import React, { useState } from "react";
import styles from "./QuoteRequestPage.module.css";

const QuoteRequestPage = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [deviceModel, setDeviceModel] = useState("");
    const [repairsNeeded, setRepairsNeeded] = useState("");
    const [anythingElse, setAnythingElse] = useState("");
    // Honeypot field
    const [fullName, setFullName] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            content: {
                firstName,
                lastName,
                email,
                phone,
                deviceModel,
                repairsNeeded,
                anythingElse,
                fullName
            }
        };

        try {
            console.log(`Sending payload! ${JSON.stringify(payload)}`);

            const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/ad-quote-request`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`Server error: ${response.statusText}`);
            }
            console.log(`Quote request succeeded: ${response}`);
            if (window.gtag_report_conversion) {
                window.gtag_report_conversion('/thank-you');
            } else {
                window.location.href = '/thank-you';
            }
        } catch (error) {
            console.error("Failed to send quote request:", error);
        }
    };

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h1 style={{ fontFamily: 'qfont, sans-serif' }}>Quik Fix Repair</h1>
                <p>Need a fast and reliable repair in Tucson? Look no further!</p>
                <p>Fill out the form below to get your instant repair quote.</p>
            </header>

            <form className={styles.quoteForm} onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                    <label>First Name *</label>
                    <input
                        type="text"
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label>Last Name *</label>
                    <input
                        type="text"
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label>Contact Number *</label>
                    <input
                        type="tel"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label>Email *</label>
                    <input
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label>Device Model *</label>
                    <input
                        type="text"
                        required
                        value={deviceModel}
                        onChange={(e) => setDeviceModel(e.target.value)}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label>Repair Needed *</label>
                    <textarea
                        rows="3"
                        required
                        value={repairsNeeded}
                        onChange={(e) => setRepairsNeeded(e.target.value)}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label>Anything else we should know?</label>
                    <textarea
                        rows="3"
                        value={anythingElse}
                        onChange={(e) => setAnythingElse(e.target.value)}
                    />
                </div>

                <div style={{ display: "none" }}>
                    <label htmlFor="fullName">Full Name</label>
                    <input
                        id="fullName"
                        name="fullName"
                        type="text"
                        autoComplete="off"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                </div>

                <button type="submit" className={styles.submitBtn}>
                    Get Your Repair Quote
                </button>
            </form>

            <footer className={styles.footer}>
                <p>© {new Date().getFullYear()} Quik Fix Repair</p>
            </footer>
        </div>
    );
};

export default QuoteRequestPage;
