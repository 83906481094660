import React from 'react';
import styles from './LocationsPage.module.css';

const locations = [
    {
        name: 'Central (Campbell and Glenn)',
        address: '2843 N. Campbell Ave Tucson, AZ 85719',
        phone: '520-850-6637',
        hours: 'Daily 10am - 7pm | Sun 11am - 5pm',
        mapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26991.792526798166!2d-110.96767252135258!3d32.258780000000016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d673a2e98618c3%3A0x957efec169890de3!2sQuik%20Fix%20Phone%20Repair!5e0!3m2!1sen!2sus!4v1676572576348!5m2!1sen!2sus'
    },
    {
        name: 'South (Irvington and 12th)',
        address: '702 W Irvington Rd #140 Tucson, AZ 85714',
        phone: '520-463-7044',
        hours: 'Daily 10am - 7pm | Sun 11am - 5pm',
        mapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27020.070756765977!2d-111.01254248437499!3d32.163550600000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d67a13c37755a7%3A0x3aac11790408fab3!2sQuik%20Fix%20Phone%20Repair%20-%20South%20Tucson!5e0!3m2!1sen!2sus!4v1676572614442!5m2!1sen!2sus'
    },
    {
        name: 'East (Broadway and Wilmot)',
        address: '245 S Wilmot Rd #155 Tucson, AZ 85711',
        phone: '520-448-9611',
        hours: 'Daily 10am - 7pm | Sun 11am - 5pm',
        mapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19094.9562013709!2d-110.87967272820309!3d32.216535979730885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d66f9fbb00c57b%3A0xc7d30e1e173ec5bd!2sQuik%20Fix%20Phone%20Repair%20-%20East%20Tucson!5e0!3m2!1sen!2sus!4v1676572545739!5m2!1sen!2sus'
    }
];

const LocationsPage = () => {
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h2 style={{ fontFamily: 'qfont, sans-serif' }}>Quik Fix Repair</h2>
                <h1>Our Locations</h1>
                <p>Visit any of our convenient locations throughout Tucson.</p>
            </header>
            <div className={styles.locationsContainer}>
                {locations.map((location, index) => (
                    <div key={index} className={styles.locationCard}>
                        <h3>{location.name}</h3>
                        <iframe
                            title={location.name}
                            src={location.mapSrc}
                            width="100%"
                            height="200"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                        <p>
                            {location.address}
                            <br />
                            <a className={styles.phoneNumber} href={`tel:${location.phone}`}><b>{location.phone}</b></a>
                            <br />
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LocationsPage;
