import React from "react";
import styles from "./LandingPage.module.css";
import newPhone from '../../../images/phone.webp';
import brokenPhone from '../../../images/brokenPhone.webp';
import Button from "../../Button/Button.js";
import SwipeImage from "../../SwipeImage/SwipeImage.js";

const LandingPage = () => {

    const handleClickForLocations = (e) => {
        e.stopPropagation();
        window.location.href = "/locations";
    };

    const handleClickForQuote = () => {
        window.location.href = "/quote";
    };

    return (
        <div className={styles.landingContainer} onClick={handleClickForQuote}>
            <header className={styles.header}>
                <h1 style={{ fontFamily: 'qfont, sans-serif' }}>Quik Fix Repair</h1>
                <p className={styles.blurb}>Guaranteed lowest price, fixed today.</p>
                <p className={styles.blurb}>Why go anywhere else?</p>
            </header>
            <SwipeImage initialImage={brokenPhone} finalImage={newPhone} />
            <div>
                <Button className={styles.button} onClick={handleClickForLocations}>
                    Visit Us Today!
                </Button>
                <Button onClick={handleClickForQuote}>
                    Get A Quote!
                </Button>
            </div>
            <footer className={styles.footer}>
                <p>&copy; {new Date().getFullYear()} Quik Fix Repair</p>
            </footer>
        </div>
    );
}

export default LandingPage;
