import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './components/pages/LandingPage/LandingPage.js';
import QuoteRequestPage from './components/pages/QuoteRequestPage/QuoteRequestPage.js';
import ThankYouPage from './components/pages/ThankYouPage/ThankYouPage.js';
import LocationsPage from './components/pages/LocationsPage/LocationsPage.js';
import './fonts/fonts.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/quote" element={<QuoteRequestPage />} />
          <Route path="/locations" element={<LocationsPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
