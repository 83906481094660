import React from "react";
import styles from "./ThankYouPage.module.css";

const ThankYouPage = () => {
    return (
        <div className={styles.container}>

            <header className={styles.header}>
                <h1>Thank You!</h1>
                <p>Your quote request has been successfully submitted.</p>
                <p>
                    We appreciate your interest in Quik Fix Phone Repair. One of our experts
                    will contact you shortly.
                </p>
            </header>

            <section className={styles.locationsSection}>
                <h2>Don't Feel Like Waiting?</h2>
                <p>Stop by any of our convenient Tucson locations instead!</p>

                <div className={styles.locationsContainer}>

                    {/* Central Tucson */}
                    <div className={styles.locationCard}>
                        <h3>Central Tucson</h3>
                        <iframe
                            title="central"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26991.792526798166!2d-110.96767252135258!3d32.258780000000016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d673a2e98618c3%3A0x957efec169890de3!2sQuik%20Fix%20Phone%20Repair!5e0!3m2!1sen!2sus!4v1676572576348!5m2!1sen!2sus"
                            width="100%"
                            height="200"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                        <p>
                            2843 N. Campbell Ave Tucson, AZ 85719
                            <br />
                            <span>Phone:</span>{" "}
                            <a href="tel:5208506637"><b>520-850-6637</b></a>
                            <br />
                            <span className={styles.redLabel}>Hours:</span> Daily 10am - 7pm | Sun 11am - 5pm
                        </p>
                    </div>

                    {/* South Side */}
                    <div className={styles.locationCard}>
                        <h3>South Side</h3>
                        <iframe
                            title="south"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27020.070756765977!2d-111.01254248437499!3d32.163550600000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d67a13c37755a7%3A0x3aac11790408fab3!2sQuik%20Fix%20Phone%20Repair%20-%20South%20Tucson!5e0!3m2!1sen!2sus!4v1676572614442!5m2!1sen!2sus"
                            width="100%"
                            height="200"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                        <p>
                            702 W Irvington Rd #140, Tucson, AZ 85714
                            <br />
                            <span>Phone:</span>{" "}
                            <a href="tel:5204637044"><b>520-463-7044</b></a>
                            <br />
                            <span className={styles.redLabel}>Hours:</span> Daily 10am - 7pm | Sun 11am - 5pm
                        </p>
                    </div>

                    {/* East Side */}
                    <div className={styles.locationCard}>
                        <h3>East Side</h3>
                        <iframe
                            title="east"
                            width="100%"
                            height="200"
                            style={{ border: 0 }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19094.9562013709!2d-110.87967272820309!3d32.216535979730885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d66f9fbb00c57b%3A0xc7d30e1e173ec5bd!2sQuik%20Fix%20Phone%20Repair%20-%20East%20Tucson!5e0!3m2!1sen!2sus!4v1676572545739!5m2!1sen!2sus"
                            allowFullScreen="allowfullscreen"
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                        <p>
                            245 S Wilmot Rd #155, Tucson, AZ 85711
                            <br />
                            <span>Phone:</span>{" "}
                            <a href="tel:5204489611"><strong>520-448-9611</strong></a>
                            <br />
                            <span className={styles.redLabel}>Hours:</span> Daily 10am - 7pm | Sun 11am - 5pm
                        </p>
                    </div>
                </div>
            </section>

            <footer className={styles.footer}>
                <p>© {new Date().getFullYear()} Quik Fix Phone Repair</p>
            </footer>
        </div>
    );
};

export default ThankYouPage;
